import axios from 'axios';

export async function getLocationId(query) {
  const response = await axios.get('/api/location', {
    params: {
      query: query,
      limit: 1
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data[0].id;
}