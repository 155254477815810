import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { getLocationId } from './components/GetLocation';


function App() {
  
  const [isLoading, setIsLoading] = useState(true);

  const [overfallLocId, setOverfallLocId] = useState(null);
  const [haypointLocId, setHaypointLocId] = useState(null);
  
  const [thursdaySwellData, setThursdaySwellData] = useState(null);
  const [fridaySwellData, setFridaySwellData] = useState(null);
  const [saturdaySwellData, setSaturdaySwellData] = useState(null);
  const [thursdaySwellPeriod, setThursdaySwellPeriod] = useState(null);
  const [fridaySwellPeriod, setFridaySwellPeriod] = useState(null);
  const [saturdaySwellPeriod, setSaturdaySwellPeriod] = useState(null);
  const [thursdayWindSpeed, setThursdayWindSpeed] = useState(null);
  const [fridayWindSpeed, setFridayWindSpeed] = useState(null);
  const [saturdayWindSpeed, setSaturdayWindSpeed] = useState(null);
  const [thursdayRainPercentData, setThursdayRainPercetData] = useState(null);
  const [fridayRainPercentData, setFridayRainPercetData] = useState(null);
  const [saturdayRainPercentData, setSaturdayRainPercetData] = useState(null);

  const [nextNewMoonDate, setNextNewMoonDate] = useState(null)
  const [nextFullMoonDate, setNextFullMoonDate] = useState(null)

  const [thursdayTides, setThursdayTides] = useState([]);
  const [fridayTides, setFridayTides] = useState([]);
  const [saturdayTides, setSaturdayTides] = useState([]);



  //function to format time into user-friendly format
  function formatTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    const strTime = hours + ':' + minutes + ampm;
    return strTime;
  }


  
  function getNextDayDate(dayIndex) {
    let now = moment();
    let diff = (dayIndex + 7 - now.day()) % 7;
    return now.add(diff, 'days').format('Do of MMM');
  }
   
  const THURSDAY = 4;
  const FRIDAY = 5;
  const SATURDAY = 6;

  useEffect(() => {
    getLocationId('overfall reef').then(setOverfallLocId);
  }, []);
  
  useEffect(() => {
    getLocationId('hay point qld').then(setHaypointLocId);
  }, []);

let thursdayDate = getNextDayDate(THURSDAY);
let fridayDate = getNextDayDate(FRIDAY);
let saturdayDate = getNextDayDate(SATURDAY);

  useEffect(() => {
    async function getHaypointWeatherData() {
      const today = new Date();
      
      const locId = haypointLocId;
  
      const response = await axios.get('/api/forecast', {
        params: {
          forecasts: 'tides',
          days: 7,
          locId: locId,
          startDate: `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      const tideData = response.data.forecasts.tides.days;
      const currentDay = today.getDay();
      let daysUntilThursday, daysUntilFriday, daysUntilSaturday;
      if (currentDay === 4) {
        daysUntilThursday = 0;
      } else {
        daysUntilThursday = (11 - currentDay) % 7;
      }
      if (currentDay === 5) {
        daysUntilFriday = 0;
      } else {
        daysUntilFriday = (12 - currentDay) % 7;
      }
      if (currentDay === 6) {
        daysUntilSaturday = 0;
      } else {
        daysUntilSaturday = (13 - currentDay) % 7;
      }
      
      setThursdayTides(tideData[daysUntilThursday].entries);
      setFridayTides(tideData[daysUntilFriday].entries);
      setSaturdayTides(tideData[daysUntilSaturday].entries);
    }
  
    if (haypointLocId) {
      getHaypointWeatherData();
    }
  }, [haypointLocId]);


  useEffect(() => {
    async function getOverfallWeatherData() {
      setIsLoading(true);
      const today = new Date();
      const dayOfWeek = today.getDay();
      let daysUntilThursday, daysUntilFriday, daysUntilSaturday;
      
      if (dayOfWeek === 4) {
        daysUntilThursday = 0;
      } else {
        daysUntilThursday = (11 - dayOfWeek) % 7;
      }
      
      if (dayOfWeek === 5) {
        daysUntilFriday = 0;
      } else {
        daysUntilFriday = (12 - dayOfWeek) % 7;
      }
      
      if (dayOfWeek === 6) {
        daysUntilSaturday = 0;
      } else {
        daysUntilSaturday = (13 - dayOfWeek) % 7;
      }

      const locId = overfallLocId;

      const response = await axios.get('/api/forecast', {
        params: {
          forecasts: 'swell,wind,rainfallprobability,moonphases',
          days: 7,
          locId: locId,
          startDate: `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setIsLoading(false);
  
      const swellDays = response.data.forecasts.swell.days;
      const windDays = response.data.forecasts.wind.days;
      const rainfallDays = response.data.forecasts.rainfallprobability.days;
      const moonQuarters = response.data.forecasts.moonphases.quarters;

      const newMoon = moonQuarters.find(moon => moon.phase === 'New');
      const fullMoon = moonQuarters.find(moon => moon.phase === 'Full');

      const nextNewMoonDate = newMoon ? moment(newMoon.dateTime).format('dddd, Do of MMM') : 'No new moon data available';
      const nextFullMoonDate = fullMoon ? moment(fullMoon.dateTime).format('dddd, Do of MMM') : 'No full moon data available';

      setNextNewMoonDate(nextNewMoonDate);
      setNextFullMoonDate(nextFullMoonDate);

      const calculateAverageHeight = (day) => {
        let totalHeight = 0;
        let count = 0;
  
        const entries = day.entries.filter(entry => {
          const hour = new Date(entry.dateTime).getHours();
          return hour >= 3 && hour <= 14;
        });
  
        entries.forEach(entry => {
          totalHeight += entry.height;
          count++;
        });
  
        return totalHeight / count;
      };
  
      setThursdaySwellData(calculateAverageHeight(swellDays[daysUntilThursday]));
      setFridaySwellData(calculateAverageHeight(swellDays[daysUntilFriday]));
      setSaturdaySwellData(calculateAverageHeight(swellDays[daysUntilSaturday]));

      const calculateAveragePeriod = (day) => {
        let totalPeriod = 0;
        let count = 0;
  
        const entries = day.entries.filter(entry => {
          const hour = new Date(entry.dateTime).getHours();
          return hour >= 3 && hour <= 14;
        });
  
        entries.forEach(entry => {
          totalPeriod += entry.period;
          count++;
        });
  
        return totalPeriod / count;
      };
  
      setThursdaySwellPeriod(calculateAveragePeriod(swellDays[daysUntilThursday]));
      setFridaySwellPeriod(calculateAveragePeriod(swellDays[daysUntilFriday]));
      setSaturdaySwellPeriod(calculateAveragePeriod(swellDays[daysUntilSaturday]));

      setThursdayWindSpeed(calculateAverageWindSpeed(windDays[daysUntilThursday]));
      setFridayWindSpeed(calculateAverageWindSpeed(windDays[daysUntilFriday]));
      setSaturdayWindSpeed(calculateAverageWindSpeed(windDays[daysUntilSaturday]));

      setThursdayRainPercetData(calculateAverageRainfallProbability(rainfallDays[daysUntilThursday]));
      setFridayRainPercetData(calculateAverageRainfallProbability(rainfallDays[daysUntilFriday]));
      setSaturdayRainPercetData(calculateAverageRainfallProbability(rainfallDays[daysUntilSaturday]));
    }

    const calculateAverageWindSpeed = (day) => {
      let totalSpeed = 0;
      let count = 0;
    
      const entries = day.entries.filter(entry => {
        const hour = new Date(entry.dateTime).getHours();
        return hour >= 3 && hour <= 14;
      });
    
      entries.forEach(entry => {
        totalSpeed += entry.speed;
        count++;
      });
    
      return totalSpeed / count;
    };
    
    const calculateAverageRainfallProbability = (day) => {
      let totalProbability = 0;
      let count = 0;
    
      const entries = day.entries.filter(entry => {
        const hour = new Date(entry.dateTime).getHours();
        return hour >= 3 && hour <= 14;
      });
    
      entries.forEach(entry => {
        totalProbability += entry.probability;
        count++;
      });
    
      return totalProbability / count;
    };

    
    if (overfallLocId) {
      getOverfallWeatherData();
    }
  }, [overfallLocId]);
  

  return (
    <div className="flex justify-center bg-base-300 min-h-screen">
      <div className="flex flex-col container mx-5">
        <header className="">
          <h1 className="text-slate-300 pt-5 pb-2 text-4xl text-center">☀️ rzk<strong>weather 🌊</strong></h1>
          <h1 className='text-slate-300 text-sm text-center pb-2'>
            Beverley group fishing weather at a glance
          </h1>
        </header>
        <main className='text-center'>
          <h1 className='text-slate-300 text-4xl py-3 font-bold'>News</h1>
          <h2 className='text-slate-300 text-xl pb-1 font-bold'>Moon Phases</h2>
          <p className='text-md pb-1'>Upcoming target moon phases</p>
          {isLoading ? (
            <div className='flex justify-center pt-3'>
              <span className="loading loading-infinity loading-lg"></span>                  
            </div>
          ) : (
            <>
            <p><strong>New Moon</strong>: {nextNewMoonDate} </p>
            <p><strong>Full Moon</strong>: {nextFullMoonDate} </p>
            </>
          )
          }

          <h1 className='text-slate-300 text-4xl py-3 font-bold'>Forecast</h1>
          <p className='pb-3'>Weather data for each day is an average of the forecast from 3am to 2pm.</p>
          <p className=''>Tides are for the Hay Point boat ramp.</p>
          
            <div className="divider"></div>
            
            <div className='grid grid-cols-1'>

              <div className='text-2xl text-slate-300 font-bold pb-8'>Thursday, {thursdayDate} </div>
              {isLoading ? (
                <div className='flex justify-center pt-3'>
                  <span className="loading loading-infinity loading-lg"></span>                  
                </div>
              ) : (
                <>
                <div className='flex justify-center mb-3'>
                  <div className='grid grid-cols-1 md:flex md:flex-row justify-center gap-4 pb-5'>
                    <div className="card w-60  bg-neutral text-neutral-content">
                      <div className="card-body items-center text-center">
                        <h2 className="card-title text-slate-200 font-bold">Swell</h2>
                        <p className='text-slate-200 text-lg'>{Math.floor(thursdaySwellData * 10) / 10}m @ {Math.floor(thursdaySwellPeriod)}secs</p>
                      </div>
                    </div>
                    <div className="card w-60  bg-neutral text-neutral-content">
                      <div className="card-body items-center text-center">
                        <h2 className="card-title text-slate-200 font-bold">Wind</h2>
                        <p className='text-slate-200 text-lg'>{Math.floor(thursdayWindSpeed * 0.539957)} knots</p>
                      </div>
                    </div>
                    <div className="card w-60  bg-neutral text-neutral-content">
                      <div className="card-body items-center text-center">
                        <h2 className="card-title text-slate-200 font-bold">Rain Probability</h2>
                        <p className='text-slate-200 text-lg'>{thursdayRainPercentData}%</p>
                      </div>
                    </div>
                  </div>
                </div>                
                <h1 className='text-2xl pb-5 text-slate-300'>Tides</h1>
                <div className='flex justify-center pb-3'>
                  <div className='grid grid-rows-4 gap-3 w-60 text-slate-300'>
                      {thursdayTides.map(tide => (
                        <div key={tide.dateTime} className='flex justify-between rounded-xl bg-neutral font-bold text-l p-3'>                            
                            <div className='px-2'>{formatTime(tide.dateTime)}</div>
                            <div className='px-3'>{tide.type.toUpperCase()}</div>
                            <div className='px-3'>{tide.height}m</div>
                        </div>
                      ))}
                  </div>
                </div>                
                </>
              )}
                
                
                <div className="divider"></div>

                <div className='grid grid-cols-1'>
                <div className='text-2xl text-slate-300 font-bold pb-8'>Friday, {fridayDate} </div>
                {isLoading ? (
                  <div className='flex justify-center pt-3'>
                    <span className="loading loading-infinity loading-lg"></span>
                  </div>
                ) : (
                  <>
                  <div className='flex justify-center mb-3'>
                    <div className='grid grid-cols-1 md:flex md:flex-row justify-center gap-4 pb-5'>
                      <div className="card w-60  bg-neutral text-neutral-content">
                        <div className="card-body items-center text-center">
                          <h2 className="card-title text-slate-200 font-bold">Swell</h2>
                          <p className='text-slate-200 text-lg'>{Math.floor(fridaySwellData * 10) / 10}m @ {Math.floor(fridaySwellPeriod)}secs</p>
                        </div>
                      </div>
                      <div className="card w-60  bg-neutral text-neutral-content">
                        <div className="card-body items-center text-center">
                          <h2 className="card-title text-slate-200 font-bold">Wind</h2>
                          <p className='text-slate-200 text-lg'>{Math.floor(fridayWindSpeed * 0.539957)} knots</p>
                        </div>
                      </div>
                      <div className="card w-60  bg-neutral text-neutral-content">
                        <div className="card-body items-center text-center">
                          <h2 className="card-title text-slate-200 font-bold">Rain Probability</h2>
                          <p className='text-slate-200 text-lg'>{fridayRainPercentData}%</p>
                        </div>
                      </div>
                    </div>
                  </div>                
                  <h1 className='text-2xl pb-5 text-slate-300'>Tides</h1>
                  <div className='flex justify-center pb-5'>
                    <div className='grid grid-rows-4 gap-3 w-60 text-slate-300'>
                        {fridayTides.map(tide => (
                          <div key={tide.dateTime} className='flex justify-between rounded-xl bg-neutral font-bold text-l p-3'>                            
                              <div className='px-2'>{formatTime(tide.dateTime)}</div>
                              <div className='px-3'>{tide.type.toUpperCase()}</div>
                              <div className='px-3'>{tide.height}m</div>
                          </div>
                        ))}
                    </div>
                  </div>                
                  </>
                )}

                <div className="divider"></div>

                <div className='grid grid-cols-1'>
                <div className='text-2xl text-slate-300 font-bold pb-8'>Saturday, {saturdayDate} </div>
                {isLoading ? (
                  <div className='flex justify-center pt-3'>
                    <span className="loading loading-infinity loading-lg"></span>
                  </div>
                ) : (
                  <>
                  <div className='flex justify-center mb-3'>
                    <div className='grid grid-cols-1 md:flex md:flex-row justify-center gap-4 pb-5'>
                      <div className="card w-60  bg-neutral text-neutral-content">
                        <div className="card-body items-center text-center">
                          <h2 className="card-title text-slate-200 font-bold">Swell</h2>
                          <p className='text-slate-200 text-lg'>{Math.floor(saturdaySwellData * 10) / 10}m @ {Math.floor(saturdaySwellPeriod)}secs</p>
                        </div>
                      </div>
                      <div className="card w-60  bg-neutral text-neutral-content">
                        <div className="card-body items-center text-center">
                          <h2 className="card-title text-slate-200 font-bold">Wind</h2>
                          <p className='text-slate-200 text-lg'>{Math.floor(saturdayWindSpeed * 0.539957)} knots</p>
                        </div>
                      </div>
                      <div className="card w-60  bg-neutral text-neutral-content">
                        <div className="card-body items-center text-center">
                          <h2 className="card-title text-slate-200 font-bold">Rain Probability</h2>
                          <p className='text-slate-200 text-lg'>{saturdayRainPercentData}%</p>
                        </div>
                      </div>
                    </div>
                  </div>                
                  <h1 className='text-2xl text-slate-300 pb-5'>Tides</h1>
                  <div className='flex justify-center pb-3'>
                    <div className='grid grid-rows-4 gap-3 w-60 text-slate-300'>
                        {saturdayTides.map(tide => (
                          <div key={tide.dateTime} className='flex justify-between rounded-xl bg-neutral font-bold text-l p-3'>                            
                              <div className='px-2'>{formatTime(tide.dateTime)}</div>
                              <div className='px-3'>{tide.type.toUpperCase()}</div>
                              <div className='px-3'>{tide.height}m</div>
                          </div>
                        ))}
                    </div>
                  </div>                
                  </>
                )}
                  <div className="divider"></div>
                  
                  <div className='pb-5'>
                    <p>created by <a href='https://github.com/RZKtheKid'>RZKtheKid</a></p>
                  </div>  
                </div>  
              </div>
            </div>
        </main>

      
      </div>
    </div>
  );
}

export default App;
